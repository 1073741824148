"use client";

import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import Main from "./Components/Main";
import reportWebVitals from "./reportWebVitals";
import { ThemeWrapper } from "./Components/Theme/ThemeWrapper";
import "./i18n/i18n";
import { Button, CssBaseline } from "@mui/material";
import { MessageServiceProvider } from "./Components/MessageHandler/MessageService";
import { ErrorBoundary, FallbackProps } from "react-error-boundary";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

function Fallback({ error, resetErrorBoundary }: FallbackProps) {
	// Call resetErrorBoundary() to reset the error boundary and retry the render.

	return (
		<div role="alert">
			<p>Error:</p>
			<pre style={{ color: "red" }}>{error.message}</pre>

			<Button variant="contained" color="info" onClick={resetErrorBoundary}>
				{"Reload"}
			</Button>
		</div>
	);
}

// The theme provider is declare here and handled in Main
root.render(
	<React.StrictMode>
		<ErrorBoundary
			FallbackComponent={Fallback}
			onReset={(details) => {
				// Reset the state of your app so the error doesn't happen again
				// ReloadApp();
			}}
		>
			<ThemeWrapper>
				<MessageServiceProvider>
					<CssBaseline />
					<Main />
				</MessageServiceProvider>
			</ThemeWrapper>
		</ErrorBoundary>
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
