import { Box, CircularProgress, Container, Theme, ThemeProvider, Typography, createTheme, useTheme } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import './Home.scss';
import { useTranslation } from 'react-i18next';
import { useMessage } from '../MessageHandler/MessageService';
import { MessageOptions } from '../MessageHandler/MessageDialog';
import { ReactComponent as Logo } from "./labware_logo.svg";
import { alpha } from "@mui/material";

interface HomeProps {
	config_id?: string;
}

interface CustomUrl {
	name: string;
	link: string;
	img: string;
}

interface AppConfigurationInterface {
   id: string;
   title_img: string;
   title_color: string;
   background_img: string;
   urls: CustomUrl[]
   themes: {
      system: {
         themeName: string;
         color:{
            primary: string;
            secondary: string;
            background: string;
         }
         font: {
            fontTitle: string;
            fontBody: string;
         };
      },
      custom?: {
         themeName: string;
         color:{
            primary: string;
            secondary: string;
            background: string;
         }
         font: {
            fontTitle: string;
            fontBody: string;
         };
      }
   },
   versionNumber: string;
}

const Home: FC<HomeProps> = (props) => {

	const [configuration, setConfiguration] = useState<AppConfigurationInterface | null>(null);
	const [configId, setConfigId] = useState(props.config_id ?? '');
	const [customTheme, setCustomTheme] = useState<Theme | null>(null);
	const [isLoading, setIsLoading] = useState(false);

	const { t } = useTranslation();
	const message = useMessage();
	const theme = useTheme();

	useEffect(() => {
		managePageVisibility();
	}, []);

	const getConfiguration = async (id: string) => {
		return fetch(`${'../../configurations/' + id + '/config.json?x=' + Math.random().toString()}`, {
			headers: {
				'Content-Type': 'application/json',
				'Accept': 'application/json'
			}
		})
			.then((response) =>
				response.json()
			).then((myJson) =>
				myJson
			).catch(() => null);
	}

	const managePageVisibility = async () => {
		const curConfiguration: AppConfigurationInterface | null = await getConfiguration(configId);
		if (curConfiguration) {
			const innerTheme = createTheme({
				typography: {
					fontFamily: curConfiguration.themes.system.font.fontBody ?? theme.typography.fontFamily
				},
				components: {
					MuiContainer: {
						styleOverrides: {
							root: {
								backgroundColor: curConfiguration?.themes?.system?.color?.background ?? theme.palette.background.default,
								fontFamily: curConfiguration.themes.system.font.fontBody ?? theme.typography.fontFamily
							}
						}
					}
				}
			});
			setCustomTheme(innerTheme);
			setConfiguration(curConfiguration);
		} else {
			message({ title: t("common.attention").toUpperCase(), description: t("common.error_messages.unatorized") } as MessageOptions);
		}
	}

	const sleep = (duration: number) => {
		return new Promise(resolve => setTimeout(resolve, duration));
	}

	const openLink = async (link: string) => {
		const minDuration = 1000; // 1s
		const maxDuration = 4000; // 4s
		const randomDuration = minDuration + (Math.random() * (maxDuration - minDuration));
		setIsLoading(true);
		await sleep(randomDuration);
		window.location.assign(link);

		// Android back button keeps old states on the stack
		// need to reset isLoading with a timeout to prevent page flashing while waiting outer page to be loaded
		setTimeout(() => setIsLoading(false), 500);

		return false;
	}

	return (
		<Box>
			{configuration &&
				<ThemeProvider theme={customTheme ? customTheme : theme}>
					{(isLoading) ?
						<Container maxWidth="sm" sx={{ height: '94vh', top: 0, position: 'fixed', overflowY: 'auto', background: configuration.background_img ? `url(${configuration.background_img})` : '', backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}>
							<Box
								sx={{
									display: 'flex',
									height: '90%',
									flexDirection: 'column',
									justifyContent: 'center',
									alignItems: 'center',
									color: `${configuration?.themes?.system?.color?.secondary ?? ''}`
								}}>
								{t("common.redirecting")}
								<CircularProgress sx={{ marginTop: '20px' }} size="2rem" />
							</Box>
						</Container>
						:
						<Container maxWidth="sm" sx={{ height: '94vh', top: 0, position: 'fixed', overflowY: 'auto', background: configuration.background_img ? `url(${configuration.background_img})` : '', backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}>
							<Box
								sx={{
									display: 'flex',
									justifyContent: 'center',
									padding: '20px',
									marginBottom: '180px'
								}}
							>
								{ configuration.title_img ?
									<Box sx={{width: '100px', height: '120px', background: 'white'}}>
										<Box component={'img'} src={`${configuration.title_img}`}></Box>
									</Box>
									:
									<Typography 
										variant="h4"
										sx={{
											color: configuration.title_color ? configuration.title_color : '',
											fontWeight: 'bold'
										}}>{configuration?.id ?? ''}
									</Typography> 
								}
							</Box>
							{configuration.urls.map((url: CustomUrl, index) => {
								return (
									<div key={`${'home_box_' + index}`}>
										{url.link &&
											<Box sx={{ height: 200, margin: '20px 0', border: `${configuration?.themes?.system?.color?.secondary ? '2px solid'+ configuration?.themes?.system?.color?.secondary: '2px solid'}`, borderRadius: '10px', padding: '5px' }} onClick={() => openLink(url.link)}>
												<Box
													className={url.img ? 'box-wrap' : ''}
													sx={{
														padding: '10px',
														"&::before": { backgroundImage: url.img ? `url(${url.img})` : '' }
													}}>
													<Box className={'box-content'}>
														{url?.name &&
															<Typography
																variant="h5" 
																sx={{ 
																	fontWeight: 'bold',
																	color: `${configuration?.themes?.system?.color?.secondary ? configuration?.themes?.system?.color?.secondary : ''}`,
																	// background: `${configuration?.themes?.system?.color?.background ? alpha(configuration?.themes?.system?.color?.background,0.2) : 'rgb(255, 255,255, 0.2)'}`
																}}>
																	{url?.name}
															</Typography>
														}
													</Box>
												</Box>
											</Box>
										}
									</div>
								)
							})}
						</Container>
					}
               <Box 
                  sx={{
                     display: 'flex',
                     width: '100%',
                     justifyContent: 'center',
                     alignItems: 'center',
                     position: 'absolute',
                     bottom: 0,
                     backgroundColor: 'white',
                     borderTop: '1px solid red'
                  }}
               >
                  <Typography sx={{color: 'black', marginRight: '5px'}} >Powered by</Typography>
                  <Box sx={{ display: 'flex', width: '60px', height: '60px' }}>
                     <Logo></Logo>
                  </Box>
               </Box>
				</ThemeProvider>
			}
		</Box>
	)

};

export default Home;
