import React, { Dispatch, SetStateAction } from "react";
import { AppConfig, AppTheme, buildTheme, defaultAppConfig } from "./ThemeConfig";
import { ThemeProvider } from "@mui/material";

interface ThemeContextProps {
	theme: AppTheme;
	setCurrentTheme: Dispatch<SetStateAction<AppTheme>> | null;
}


const defaultConfig: AppConfig = defaultAppConfig;

let currentConfig: AppConfig = defaultConfig;

// Build the MUI Theme using the chosen Configurations
const defaultTheme: AppTheme = buildTheme(currentConfig);

export const ThemeContext = React.createContext<ThemeContextProps>({
	theme: defaultTheme,
	setCurrentTheme: null
});

type Props = {
	children?: React.ReactNode;
};

export const ThemeWrapper: React.FC<Props> = ({ children }) => {
	const [currentTheme, setCurrentTheme] = React.useState<AppTheme>(defaultTheme);

	return (
		<ThemeContext.Provider
			value={{
				theme: currentTheme,
				setCurrentTheme
			}}
		>
			<ThemeProvider theme={currentTheme.systemTheme}>{children}</ThemeProvider>
		</ThemeContext.Provider>
	);
};

export const useTheme = () => React.useContext(ThemeContext);
