import { Theme, createTheme, darken, lighten } from "@mui/material";
import { FontSet } from "./Fonts";
import { isDarkColor } from "../../utils/color";
import ConfigPresets from "./ThemePresets";

type RGB = `rgb(${number}, ${number}, ${number})`;
type RGBA = `rgba(${number}, ${number}, ${number}, ${number})`;
type HEX = `#${string}`;
export type Color = RGB | RGBA | HEX;

export interface ColorSet {
	primary: Color | string;
	secondary: Color | string;
	background: Color | string;
}

export interface LayoutConfig {
	themeName: string;
	color: ColorSet;
	font: FontSet;
}

export interface AppTheme {
	config: AppConfig;
	systemTheme: Theme;
	customTheme?: Theme;
}

export interface AppConfig {
	themes: ThemeConfig;
	versionNumber: string;
}

export const defaultAppConfig: AppConfig = {
	themes: {
		system: ConfigPresets.systemTheme,
	},
	versionNumber: process.env.REACT_APP_VERSION_NUMBER ?? "0.0.0"
};

export interface ThemeConfig {
	system: LayoutConfig;
	custom?: LayoutConfig;
}

export function buildTheme(config: AppConfig): AppTheme {
	function createFromConfig(subConfig: LayoutConfig): Theme {
		return createTheme({
			palette: {
				// activate Dark Mode when the chosen background is a dark color
				mode: isDarkColor(subConfig.color.background) ? "dark" : "light",
				primary: {
					main: subConfig.color.primary
				},
				secondary: {
					main: subConfig.color.secondary
				},
				background: {
					default: subConfig.color.background,
					paper: isDarkColor(subConfig.color.background) ? darken(subConfig.color.background, 0.15) : lighten(subConfig.color.background, 0.15)
				},
				info: {
					main: isDarkColor(subConfig.color.background) ? "#fff" : "rgba(0,0,0,0.87)"
				},
				success: {
					main: subConfig.color.secondary
				},
				error: {
					main: subConfig.color.primary
				}
			},
			typography: {
				fontFamily: subConfig.font.fontBody,
				fontSize: 20,
				h1: {
					fontFamily: subConfig.font.fontTitle,
					fontSize: "5.5rem",
					fontWeight: "bold"
				},
				h2: {
					fontFamily: subConfig.font.fontTitle,
					fontSize: "4.5rem",
					fontWeight: "bold"
				},
				h3: {
					fontFamily: subConfig.font.fontTitle,
					fontSize: "3.5rem",
					fontWeight: "bold"
				},
				h4: {
					fontFamily: subConfig.font.fontTitle,
					fontSize: "2.5rem",
					fontWeight: "bold"
				},
				h5: {
					fontFamily: subConfig.font.fontTitle,
					fontSize: "1.75rem",
					fontWeight: "bold"
				},
				h6: {
					fontFamily: subConfig.font.fontTitle,
					fontSize: "1.25rem",
					fontWeight: "bold"
				},
				subtitle1: {
					fontFamily: subConfig.font.fontTitle
				},
				subtitle2: {
					fontFamily: subConfig.font.fontTitle
				},
				button: {
					fontFamily: subConfig.font.fontTitle,
					fontWeight: 700,
					lineHeight: 2.4,
					textTransform: "none"
				},
				caption: {
					fontFamily: subConfig.font.fontTitle,
					fontWeight: "bold"
				}
			},
			shape: {
				borderRadius: 10
			}
		});
	}

	return {
		config: config,
		systemTheme: createFromConfig(config.themes.system)
	};
}
