import { LayoutConfig } from "./ThemeConfig";
import { Font } from "./Fonts";

// Configurations to create a system Theme branded by Labware
const defaultSystemTheme: LayoutConfig = {
	themeName: "System Labware",
	color: {
		primary: "#EC4F54",
		secondary: "#06A475",
		background: "#FFFFFF"
	},
	font: {
		fontTitle: Font.aleo,
		fontBody: Font.notoSans
	}
};

const ConfigPresets = {
	systemTheme: defaultSystemTheme
};

export default ConfigPresets;
