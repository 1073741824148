import * as React from "react";
import { MessageDialog, MessageOptions } from "./MessageDialog";

const MessageServiceContext = React.createContext<(options: MessageOptions | null) => void>(Promise.reject);

export const useMessage = () => React.useContext(MessageServiceContext);

export const MessageServiceProvider = ({ children }: { children: React.ReactNode }) => {
	const [messageState, setMessageState] = React.useState<MessageOptions | null>(null);

	const handleClose = () => {
		if (messageState?.cancelCallback) messageState?.cancelCallback();

		setMessageState(null);
	};

	const handleThirdAction = () => {
		if (messageState?.thirdActionCallback) messageState?.thirdActionCallback();

		setMessageState(null);
	};

	const handleSubmit = () => {
		messageState?.okCallback();

		setMessageState(null);
	};

	return (
		<>
			<MessageServiceContext.Provider value={setMessageState} children={children} />
			{messageState !== null ? (
				<MessageDialog
					open={Boolean(messageState)}
					okCallback={handleSubmit}
					cancelCallback={handleClose}
					thirdActionCallback={handleThirdAction}
					title={messageState.title}
					description={messageState.description}
					okLabel={messageState.okLabel}
					cancelLabel={messageState.cancelLabel}
					thirdActionLabel={messageState.thirdActionLabel}
				/>
			) : null}
		</>
	);
};
