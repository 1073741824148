import { getContrastRatio } from "@mui/material";

// a color is considered dark when is too far from white (ratio: 3)
export function isDarkColor(color: string): boolean {
	const darkLimitRatio: number = 3;
	const whiteColor: string = "#FFFFFF";
	const contrastRatio: number = getContrastRatio(color, whiteColor);

	return contrastRatio > darkLimitRatio;
}

// a color is considered dark when is too far from white (ratio: 3)
export function areColorsContrasted(color1: string, color2: string): boolean {
	const darkLimitRatio: number = 3;
	const contrastRatio: number = getContrastRatio(color1, color2);

	return contrastRatio > darkLimitRatio;
}
