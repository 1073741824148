import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationsEN from "./translations/en.json";
import translationsIT from "./translations/it.json";
import translationsDE from "./translations/de.json";
import translationsFR from "./translations/fr.json";

const resources = {
	en: {
		translation: translationsEN
	},
	it: {
		translation: translationsIT
	},
	de: {
		translation: translationsDE
	},
	fr: {
		translation: translationsFR
	}
};

const lang = window.navigator.language?.split("-")[0] ?? null;

i18n.use(initReactI18next).init({
	resources,
	fallbackLng: lang ?? "en", // default language
	keySeparator: ".", // we do not use keys in form messages.welcome
	interpolation: {
		escapeValue: false // react already safes from xss
	}
});

export default i18n;
