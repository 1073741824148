import * as React from "react";
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from "@mui/material";
import "./MessageDialog.scss";

export interface MessageOptions {
	title: string;
	description: string | React.ReactNode;
	okCallback: () => void;
	okLabel: string;
	cancelCallback?: () => void;
	cancelLabel?: string;
	thirdActionCallback?: () => void;
	thirdActionLabel?: string;
}

interface MessageDialogProps extends MessageOptions {
	open: boolean;
}

export const MessageDialog: React.FC<MessageDialogProps> = (props) => {
	return (
		<Dialog className="messageContainer" open={props.open} maxWidth="sm">
			<DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
			<DialogContent sx={{ maxHeight: "70%" }}>
				<DialogContentText component="div">{props.description}</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button color="primary" onClick={props.okCallback}>
					{props.okLabel}
				</Button>
				{props.thirdActionCallback && props.thirdActionLabel ? (
					<Button color="primary" onClick={props.thirdActionCallback}>
						{props.thirdActionLabel}
					</Button>
				) : null}
				{props.cancelCallback && props.cancelLabel ? (
					<Button color="primary" onClick={props.cancelCallback} autoFocus>
						{props.cancelLabel}
					</Button>
				) : null}
			</DialogActions>
		</Dialog>
	);
};
