import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./Main.scss";
import Home from "./Home/Home";

/** Main wrapper for the whole app. */
function Main(): JSX.Element {


	let confId = '';
	const url = window.location.pathname;
	const pathArray = url.split('/');
	if(pathArray[pathArray.length -1]){
	   confId = pathArray[pathArray.length -1];
	}else if(pathArray[pathArray.length - 2]){
	   confId = pathArray[pathArray.length - 2];
	}

	return (
		<div className="Main">
			<Router>
				<Routes>
					<Route path="/" element={<Home />} />
					<Route path="*" element={<Home config_id={confId}/>} />
				</Routes>
			</Router>
		</div>
	);
}


export default Main;
